import { all, takeEvery, put, call, takeLatest } from 'redux-saga/effects';
import moment from "moment/moment";
import omit from 'lodash/omit';
import clone from 'clone';
import { newInvoice } from '../../pages/Invoice/config';
import actions from './actions';
import actionSerie from '@iso/redux/serie/actions';
import actionsLog from '@iso/redux/log/actions';
import { simpleQueryFirestoreWithFilter, simpleQueryFirestoreWithFilterOrderBy } from '../../components/utility/firestore.helper';
import { rsf } from '@iso/lib/firebase/firebase';
import { noteSeries } from "@iso/config/site.config";

import { getAccountIdPrefix } from '@iso/components/library/helpers/local_storage';
import DateFormatter from "@iso/components/utility/DateFormatter";

import {updateValues} from "../../pages/Invoice/components/helpers";
import {generateSystemId} from "../../components/library/helpers/idGenerator";

const COLLECTION_NAME = 'invoices';
const COLLECTION_NAME_PROP = 'properties';
const COLLECTION_NAME_CLIENT = 'users';

export function* getInvoice(query) {
  try {
    const filterInvoice = { 'invoice':  {'value':  query.payload.id, 'operator': '==', 'field': 'id' }};
    const data = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + COLLECTION_NAME, filterInvoice);
    const dataSource = [];
    Object.keys(data).map((item, index) => {
      return dataSource.push({
        ...data[item],
        key: item,
      });
    });
    yield put(actions.getInvoiceSuccess(dataSource[0]));
  } catch (error) {
    yield put(actions.getInvoiceError(error));
  }
}

export function* getInvoices({ payload }) {
  try {
    const {filter} = payload;
    let filters = [];
    let orderBy = { field: "orderDate", type: "desc" };
    if (filter !==undefined){
      if (filter.type){
        filters.push({field: 'type', operator: '==', value : filter.type});
      }
      if (filter.startDate){
        filters.push({field: 'orderDate', operator: '>=', value : filter.startDate.valueOf()});
      }
      if (filter.endDate){
        filters.push({field: 'orderDate', operator: '<=', value : filter.endDate.valueOf()});
      }
      if (filter.billToId){
        filters.push({field: 'billToId', operator: '==', value : filter.billToId});
      }
      if (filter.idOwner){
        filters.push({field: 'idOwner', operator: '==', value : filter.idOwner});
      }
      if (filter.invoice_serie){
        filters.push({field: 'invoice_serie', operator: '==', value : filter.invoice_serie});
      }
    }
    filters.push({field: 'deleted_at', operator: '==', value : null});
    let data = yield simpleQueryFirestoreWithFilterOrderBy(getAccountIdPrefix() + COLLECTION_NAME, filters, orderBy);
    //yield put(actions.loadFromFireStoreSuccess(data));
    const dataSource = [];
    Object.keys(data).map((item, index) => {
      return dataSource.push({
        ...data[item],
        key: item,
      });
    });
    yield put({
      type: actions.GET_INVOICES_SUCCESS,
      invoices: dataSource
    });
  } catch (error) {
    console.log(error);
  }
}

export function* updateInvoiceSaga({ payload }) {
  const {invoice, actionName, filter } = payload;
  try {
    switch (actionName) {
      case 'delete':
        yield call(rsf.firestore.setDocument, `${getAccountIdPrefix() + COLLECTION_NAME}/${invoice.key}`, {
          deleted_at: new Date().getTime(),
        });
        yield put({
          type: actions.UPDATE_INVOICE_ITEM_BOOKING,
          payload:{
            items: invoice.invoiceList.map(i => { return { ...i }}).filter(i => (i.itemId) ? true : false),
            propertyId: invoice.invoiceProperty,
          }
        });
        let filters = [{ field: 'invoice', operator: '==', value : invoice.id }];
        let data = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + 'payments', filters);
        if (data){
          let paymentId = Object.keys(data);
          yield call(rsf.firestore.setDocument, getAccountIdPrefix() + `payments/${paymentId[0]}`, {
            deleted_at: new Date().getTime(),
          });
        }
        break;
      case 'update':
        yield call(rsf.firestore.setDocument, `${getAccountIdPrefix() + COLLECTION_NAME}/${invoice.key}`, {
          ...omit(invoice, ['key']),
        });
        break;
      default:
        yield call(rsf.firestore.addDocument, getAccountIdPrefix() + COLLECTION_NAME, invoice);
        break;
    }
    yield put({ type: actions.UPDATE_INVOICE_SUCCESS });
    yield put({ type: actions.GET_INVOICES, payload: {filter}});
  } catch (error) {
    console.log(error);
  }
}

export function* fixPaymentsInvoicesBulk_({payload}) {
  const {invoices} = payload;
  for (let y = 0; invoices.length > y; y++){
    //if (invoices[y].orderStatus === 'draft') continue;
    const previousTotal = invoices[y].totalCost;
    const updatedInvoice = updateValues(invoices[y]);
    yield call(rsf.firestore.setDocument, `${getAccountIdPrefix() + COLLECTION_NAME}/${invoices[y].key}`, {
      ...omit(updatedInvoice, ['key'])
    });
    if (previousTotal !== updatedInvoice.totalCost) console.log('-- Fixed total '+previousTotal+' to '+updatedInvoice.totalCost);
    yield setPaymentInvoice(updatedInvoice);
    console.log(y+' fixed '+updatedInvoice.id);
  }
  console.log('termino');

}

export function* setPaymentInvoice(invoice) {
  if (invoice.invoice_serie === '7' || invoice.invoice_serie === '8') return; //fix to exclude rentals invoices from payments
  let filters = [{field: 'invoice', operator: '==', value : invoice.id}];
  let data = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + 'payments', filters);
  if (Object.keys(data).length > 0 ){
    let paymentId = Object.keys(data);
    yield call(rsf.firestore.setDocument, getAccountIdPrefix() + `payments/${paymentId[0]}`, {
      ...omit(data[paymentId[0]], ['description']),
      debit: invoice.totalCost,
      type:invoice.type,
      description: invoice.type +" #" + invoice.number,
    });
  }else{
    const payment = {
      credit: "0.00",
      debit: invoice.totalCost,
      date: invoice.orderDate,
      deleted_at: null,
      description: "Invoice #" + invoice.number,
      invoice: invoice.id,
      type: invoice.type,
      billToId: invoice.billToId,
      idOwner: invoice.billToId,//fix replaced idOwner
      createdAt: new Date().getTime(),
      id: 1,
    }
    yield call(rsf.firestore.addDocument, getAccountIdPrefix() + `payments`, payment);
  }
}

export function* updateInvoicesBulk({ payload }) {
  const getYearFromTimestamp = (d) => {
    let date = new Date(d);
    return date.getFullYear().toString();
  }
  const { invoices, series, filter } = payload;
  try {
    let objectSeries = Object.keys(series);
    for (let i = 0; objectSeries.length > i ; i++){
      for (let y = 0; invoices.length > y; y++){
        if (!invoices[y].invoice_serie || invoices[y].invoice_serie === "0")
          invoices[y].invoice_serie = (invoices[y].type === "invoice") ? "1" : "101";
        if (String(series[objectSeries[i]].id) === invoices[y].invoice_serie && invoices[y].orderStatus === 'draft' ){
          let found = false;
          let year = getYearFromTimestamp(invoices[y].orderDate);
          if (series[objectSeries[i]].years !== undefined) {
            Object.keys(series[objectSeries[i]].years).forEach((y) => {
              if (year === y){
                found = true;
                series[objectSeries[i]].years[y]++;
              }
            });
          }else{
            series[objectSeries[i]].years = {};
          }
          if(!found){
            series[objectSeries[i]].years[year] = 1;
          }
          invoices[y].orderStatus = invoices[y].type;
          invoices[y].number = String(series[objectSeries[i]].id) + "/" + String(series[objectSeries[i]].years[year]).padStart(4, "0");
          yield call(rsf.firestore.setDocument, `${getAccountIdPrefix() + COLLECTION_NAME}/${invoices[y].key}`, {
            ...omit(invoices[y], ['key'])
          });
          yield setPaymentInvoice(invoices[y]);
          yield put({type: actionSerie.SAVE_INTO_FIRESTORE, payload: { data: { key: objectSeries[i], ...series[objectSeries[i]] }, actionName: 'update' }});
        }
      }
    }
    yield put({ type: actions.UPDATE_INVOICES_BULK_SUCCESS, payload: {}});
  } catch (error) {
    console.log(error);
  }
  yield put({ type: actions.GET_INVOICES, payload: {filter}});
}

export function* fixPaymentsInvoicesBulk(){
  let data = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + COLLECTION_NAME, []);
  const dataSource = [];
  Object.keys(data).map((item, index) => {
    return dataSource.push({
      ...data[item],
      key: item,
    });
  });
  for (let i = 0; i < dataSource.length; i++){
    if (dataSource[i].id && (dataSource[i].invoice_serie !=='7' && dataSource[i].invoice_serie !=='8')){
      let filters = [{field: 'invoice', operator: '==', value : dataSource[i].id}];
      let data = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + 'payments', filters);
      if (data && Object.keys(data).length !== 0){
        let paymentId = Object.keys(data);
        yield call(rsf.firestore.setDocument, getAccountIdPrefix() + `payments/${paymentId[0]}`, {
          ...data[paymentId[0]],
          type: dataSource[i].type,
        });
        console.log('updated payment '+paymentId[0])
      }
    }
  }
  console.log('end');
}

export function* updateDataUserInvoice({payload}) {
  console.log(payload);
  let filters = [];
  filters.push({field: 'billToId', operator: '==', value : payload.dataUser.id});
  filters.push({field: 'orderStatus', operator: '==', value : 'draft'});
  let data = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix()+ COLLECTION_NAME, filters);
  const dataSource = [];
  for (const id in data) {
      if (Object.prototype.hasOwnProperty.call(data, id)) {
        //I MODIFY THE INVOICE VALUES
        const item = data[id];
        item.billTo = payload.dataUser.business_name;
        item.cifTo = payload.dataUser.cif;
        item.billToAddress = payload.dataUser.address + " - " + payload.dataUser.address;
        item.companyTo = payload.dataUser.business_name;
        dataSource.push({
          ...item,
          key: id,
        });
      }
   }
  ///////////////////
  const fieldsToOmit = ['key'];
  for (let i = 0; i < dataSource.length; i++){
    yield call(rsf.firestore.setDocument, `${getAccountIdPrefix() + COLLECTION_NAME}/${dataSource[i].key}`, {
      ...omit(dataSource[i], fieldsToOmit),
    });
  }
  return yield false;
}

export function* updateInvoiceItemBooking({payload}) {
  const { propertyId, bookingId, items } = payload;

  const setNewInvoice = (item, client, property ,bookingId) => {
    let itemDate = new Date(item.createdAt);
    let itemType = (item.invoice) ? 'invoice': 'note';
    let invoice = clone(newInvoice);
    const newId = generateSystemId();
    invoice.id = newId;
    invoice.number = `#${newId}`;
    invoice.orderStatus = 'draft';
    invoice.orderDate = DateFormatter.getLastDayMonthValue(itemDate);
    invoice.orderMonth = itemDate.getMonth();
    invoice.billTo = client.business_name;
    invoice.billToId = property[0].ownerId;
    invoice.ownerId = property[0].ownerId;
    invoice.billToAddress = `${client.address} ${client.city ? "- " + client.city : ""} ${client.state ? "- " + client.state : ""}`;
    invoice.cifTo = client.cif;
    invoice.companyTo = client.business_name;
    invoice.invoice_serie = (itemType === 'invoice') ? (client.invoice_serie).toString() : noteSeries.normal.id.toString();
    invoice.invoiceProperty = property[0].id;
    invoice.propertyName = property[0].name;
    invoice.invoiceList.pop();
    invoice.vatRate = (item.invoice) ? 21 : 0;
    invoice.type = itemType;
    invoice.invoiceList = getItems(invoice.invoiceList, item, property, bookingId);
    return updateValues(invoice);
  }

  const updateInvoice = (item, invoice, property, bookingId) => {
    invoice.invoiceList = getItems(invoice.invoiceList, item, property, bookingId);
    return updateValues(invoice);
  }

  function* getPropertyFromBooking (propertyId) {
    let filtersProp = [];
    filtersProp.push({ field: 'id', operator: '==', value : propertyId });
    let dataProperty = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + COLLECTION_NAME_PROP, filtersProp);
    let property = Object.keys(dataProperty).map((i, index) => {
      return {
        ...dataProperty[i],
        key: i,
      };
    });
    return yield property;
  }

  function* getClientFromProperty (property) {
    let filtersClient = [];
    filtersClient.push({ field: 'id', operator: '==', value : property[0].ownerId });
    let dataClient = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + COLLECTION_NAME_CLIENT, filtersClient);
    let client = Object.keys(dataClient).map((i, index) => {
      return {
        ...dataClient[i],
        key: i,
      };
    });
    return yield client;
  }

  function* getInvoiceFromBookingItem (item, property, client) {
    let filters = [];
    let itemDate = new Date(item.createdAt);
    let itemType = (item.invoice) ? 'invoice': 'note';
    let invoiceSerie = (itemType === 'invoice') ? (client.invoice_serie).toString() : noteSeries.normal.id.toString();
    const cleanedDate = moment(new Date(itemDate.getFullYear(), itemDate.getMonth(), itemDate.getDate(), 0, 0)).valueOf();
    filters.push({field: 'orderDate', operator: '>=', value : cleanedDate});
    filters.push({field: 'orderMonth', operator: '==', value : itemDate.getMonth()});
    filters.push({field: 'billToId', operator: '==', value : property[0].ownerId });
    filters.push({field: 'type', operator: '==', value : itemType });
    filters.push({field: 'invoiceProperty', operator: '==', value : property[0].id });
    filters.push({field: 'invoice_serie', operator: '==', value : invoiceSerie });
    filters.push({field: 'orderStatus', operator: '==', value : 'draft' });
    return yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + COLLECTION_NAME, filters);
  }

  const getItems = (itemList , item, property, bookingId) => {
    let cont = 1;
    let itemsAux = itemList
    .filter((i, index) => {
      if ((i.itemId === item.itemId )){
        return false;
      }
      return true;
    })
    .map( (i, index) => {
      i.key = cont;
      cont++;
      return i;
    });

    if (!item.deleted) {
      itemsAux.push({
        key: cont,
        itemName: item.itemName,
        itemType: 'T',
        noTaxable: (item.invoice) ? false : true,
        costs: item.costs,
        qty: item.qty,
        price: item.price,
        propertyId: property[0].id,
        bookingId: bookingId,
        itemId: item.itemId,
        createdAt: item.createdAt
      });
    }
    return itemsAux.sort((a, b) => {
      return a.createdAt - b.createdAt;
    }).map( (i, index) => {
      i.key = index+1;
      return i;
    });
  }

  let property = yield getPropertyFromBooking(propertyId);
  let client = yield getClientFromProperty(property);

  try {
    for (let i = 0; i < items.length; i++ ){
      let item = items[i];
      let invoices = yield getInvoiceFromBookingItem(item, property, client[0]);
      if (Object.entries(invoices).length === 0){
        let invoice = setNewInvoice(item, client[0], property, bookingId);
        yield put({ type: actions.UPDATE_INVOICE, payload: { invoice: invoice, actionName: 'insert' }});
        yield setPaymentInvoice(invoice);
        yield put({ type: actionsLog.SAVE_INTO_FIRESTORE, payload: { data: {
              id: generateSystemId(),
              user_id: null,
              text: 'item '+item.itemName+' has created a new invoice #'+invoice.id,
              description: 'New Invoice from booking',
              module: 'booking',
              type: 'success',
              checked: false,
              created_at: new Date().getTime(),
              deleted_at: null, // soft delete
              }}});
      }else{
        const keys = Object.keys(invoices);
        let invoice = updateInvoice(item, invoices[keys[0]], property, bookingId);
        invoice.key = keys[0];
        yield setPaymentInvoice(invoice);
        let actionName = ( invoice.totalCost === 0 ) ? 'delete' : 'update';
        switch (actionName) {
          case 'update':
            yield call(rsf.firestore.setDocument, `${getAccountIdPrefix() + COLLECTION_NAME}/${invoice.key}`, {
              ...omit(invoice, ['key']),
            });
            break;
          case 'delete':
            yield call(rsf.firestore.setDocument, `${getAccountIdPrefix() + COLLECTION_NAME}/${invoice.key}`, {
              deleted_at: new Date().getTime(),
            });
            break;
          default:
            break;
        }

      }
    }
  } catch(error){
      console.log(error);
  }
  yield put({ type: actions.UPDATE_INVOICE_ITEM_BOOKING_SUCCESS, payload: {}});
  return true;
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_INVOICE, getInvoice),
    takeLatest(actions.GET_INVOICES, getInvoices),
    takeEvery(actions.UPDATE_INVOICE, updateInvoiceSaga),
    takeEvery(actions.UPDATE_INVOICE_ITEM_BOOKING, updateInvoiceItemBooking),
    takeEvery(actions.UPDATE_INVOICES_BULK, updateInvoicesBulk),
    takeEvery(actions.FIX_PAYMENTS_INVOICES_BULK, fixPaymentsInvoicesBulk),
    takeEvery(actions.UPDATE_DATA_USER_INVOICE, updateDataUserInvoice),
    //takeEvery(actions.FIX_PAYMENTS_INVOICE, deleteUpdatePaymentFix)
  ]);
}
