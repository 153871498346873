import { all, takeEvery, put, call } from 'redux-saga/effects';
import actions from './actions';
import { simpleQueryFirestoreWithFilter} from '../../components/utility/firestore.helper';
import { rsf } from '@iso/lib/firebase/firebase';
import omit from 'lodash/omit';
import { getAccountIdPrefix } from '@iso/components/library/helpers/local_storage';

const COLLECTION_NAME = 'albaranes'; // change your collection
export function* getAlbaran({ payload }) {
  try {
    const {filter} = payload;
    let filters = [];
    if (filter !==undefined){
      if (filter.startDate){
        filters.push({field: 'orderDate', operator: '>=', value : filter.startDate.valueOf()});
      }
      if (filter.endDate){
        filters.push({field: 'orderDate', operator: '<=', value : filter.endDate.valueOf()});
      }
      if (filter.billToId){
        filters.push({field: 'billToId', operator: '==', value : filter.billToId});
      }
    }
    filters.push({field: 'deleted_at', operator: '==', value : null});
    let data = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + COLLECTION_NAME, filters);
    const dataSource = [];
    Object.keys(data).map((item, index) => {
      return dataSource.push({
        ...data[item],
        key: item,
      });
    });
    yield put({
      type: actions.UPDATE_ALBARAN,
      albaranes: dataSource
    });
  } catch (error) {
    console.log(error);
  }
}
export function* updateAlbaranSaga({ payload }) {
  const { albaran, actionName } = payload;
  try {
    switch (actionName) {
      case 'delete':
        yield call(rsf.firestore.setDocument, `${getAccountIdPrefix() + COLLECTION_NAME}/${albaran.key}`, {
          deleted_at: new Date().getTime(),
        });
        break;
      case 'update':
        yield call(rsf.firestore.setDocument, `${getAccountIdPrefix() + COLLECTION_NAME}/${albaran.key}`, {
          ...omit(albaran, ['key']),
        });
        break;
      default:
        yield call(rsf.firestore.addDocument, getAccountIdPrefix() + COLLECTION_NAME, albaran);
        break;
    }
    yield put({ type: actions.GET_ALBARAN });
  } catch (error) {
    console.log(error);
    //yield put(actions.saveIntoFireStoreError(error));
  }
}
    export default function* rootSaga() {
      yield all([
        takeEvery(actions.GET_ALBARAN, getAlbaran),
        takeEvery(actions.FIND_ALBARAN_SAGA, getAlbaran),
        takeEvery(actions.UPDATE_ALBARAN_SAGA, updateAlbaranSaga),
      ]);
    }
    