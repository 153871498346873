import { all, takeEvery, put, call, fork } from 'redux-saga/effects';
import firebase from 'firebase';
import actions from './actions';
import { simpleQueryFirestoreWithFilter } from "../../components/utility/firestore.helper";
const COLLECTION_NAME = 'users';
const COLLECTION_NAME_ACCOUNT = 'accounts';

export function* loginRequest() {
  yield takeEvery('LOGIN_REQUEST', function*({ payload }) {
    try {
      const { credentials } = payload;
      const auth = firebase.auth()
      const result = yield call(
        [auth, auth.signInWithEmailAndPassword],
        credentials.email,
        credentials.password
      )
      const { user } = result;
      const filter = {
        field: 'uid',
        operator: '==',
        value: user.uid
      };
      let data = yield simpleQueryFirestoreWithFilter(COLLECTION_NAME, [filter]);
      data = Object.values(data)[0];
      const filterAccount = {
        field: 'id',
        operator: '==',
        value: data.accountId ? data.accountId : ''
      };
      console.log(data);
      let dataAccount = yield simpleQueryFirestoreWithFilter(COLLECTION_NAME_ACCOUNT, [filterAccount]);
      dataAccount = Object.values(dataAccount)[0];
      const firebaseToken = yield call([user, user.getIdToken]);
      localStorage.setItem('id_token', firebaseToken);
      localStorage.setItem('id_role', data.role);
      localStorage.setItem('id_user', data.id);
      if (data.accountId)
        localStorage.setItem('account_id', data.accountId);
      if (dataAccount)
        localStorage.setItem('data_account', JSON.stringify(dataAccount));
      if (data.agencyIsActive)
        localStorage.setItem('agency_is_active', (data.agencyIsActive.toString()));
      yield put(actions.loginSuccess({ firebaseToken, role: data.role, agencyIsActive: data.agencyIsActive, userId: data.id }));
    } catch (error) {
      const error_message = { code: error.code, message: error.message };
      yield put(actions.loginError(error_message));
    }
  });
}

export function* loginSuccess() {
  yield takeEvery(actions.LOGIN_SUCCESS, function*(payload) {
    yield localStorage.setItem('id_token', payload.token);
  });
}

export function* loginError() {
  yield takeEvery(actions.LOGIN_ERROR, function*() {});
}

export function* resetPasswordRequest() {
  yield takeEvery('RESET_PASSWORD_REQUEST', function*({ payload }) {
    try {
      const { credentials } = payload;
      const auth = firebase.auth()
      yield call([auth, auth.sendPasswordResetEmail], credentials.email)
      yield put(actions.resetPasswordSuccess({ message: 'An email has been sent to your account to reset your password'}));
    } catch (error) {
      const error_message = { code: error.code, message: error.message };
      yield put(actions.resetPasswordError(error_message));
    }
  });
}

export function* resetPasswordSuccess() {
  yield takeEvery(actions.RESET_PASSWORD_SUCCESS, function*(payload) {
  yield localStorage.setItem('id_token', payload.token);
  });
}

export function* resetPasswordError() {
  yield takeEvery(actions.RESET_PASSWORD_ERROR, function*() {});
}

export function* resetPasswordConfirm() {
  yield takeEvery('RESET_PASSWORD_CONFIRM', function*({ payload }) {
    try {
      const { credentials } = payload;
      const auth = firebase.auth();
      yield call([auth, auth.confirmPasswordReset], credentials.oobCode, credentials.password)
      yield put(actions.resetPasswordConfirmSuccess({ message: 'Your password has been modified correctly'}));
    } catch (error) {
      const error_message = { code: error.code, message: error.message };
      yield put(actions.resetPasswordConfirmError(error_message));
    }
  });
}

export function* logout() {
  localStorage.removeItem('id_token');
  localStorage.removeItem('id_role');
  localStorage.removeItem('agency_is_active');
  localStorage.removeItem('account_id');
  localStorage.removeItem('account_data');
  yield put(actions.logoutSuccess());
}

export default function* rootSaga() {
  yield all([
    fork(loginRequest),
    fork(resetPasswordRequest),
    fork(resetPasswordConfirm),
    takeEvery(actions.LOGOUT, logout),
  ]);
}
