import actions from './actions';
import {generateSystemId} from "../../components/library/helpers/idGenerator";

const initState = {
  isLoading: false,
  errorMessage: false,
  payments: {},
  modalActive: false,
  payment: {
    id: generateSystemId(),
    name: '',
    price: 0,
    enabled: "true", // publish
    description: '',
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD_PAYMENTS:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_PAYMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        payments: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_PAYMENTS_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.LOAD_PAYMENTS_SUCCESS_DOCUMENT:
      return {
        ...state,
        isLoading: false,
        payment: {...payload.data },
        errorMessage: false,
      };
    case actions.TOGGLE_PAYMENTS_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        payment: payload.data == null ? initState.payment : payload.data,
      };
    case actions.PAYMENT_UPDATE:
      return {
        ...state,
        payment: payload.data,
      };
    default:
      return state;
  }
}
