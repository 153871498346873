import actions from './actions';
import {generateSystemId} from "../../components/library/helpers/idGenerator";

const initState = {
  isLoading: false,
  errorMessage: null,
  resetMessage: false,
  successMessage: null,
  users: {},
  clients: {},
  usersAutocomplete: {},
  currentUser: null,
  user: {
    key: null,
    id: generateSystemId(),
    name: '',
    user: '',
    password: '',
    email: '',
    emailCC: '',
    phone: null,
    company: '',
    nif: '',
    address: '',
    comment: '',
    role: 'admin',
    uid: '',
    invoice_serie : '1',
    isAdmin : false,
    userFirm: '',
    deleted_at: null,
		agencyIsActive: false
  }
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_USERS:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: payload.data,
      };
    case actions.LOAD_USERS_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case actions.LOAD_USER:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        currentUser: payload.data,
      };
    case actions.LOAD_USER_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case actions.LOAD_USERS_AUTOCOMPLETE:
      return {
        ...state,
        isLoading: true,
      };
    case actions.LOAD_USERS_AUTOCOMPLETE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        usersAutocomplete: payload.data,
      };
    case actions.LOAD_USERS_AUTOCOMPLETE_ERROR:
      return {
        ...state,
        isLoading: false,
      };
    case actions.UPDATE_USER:
      return {
        ...state,
        user: payload.data,
      };
    case actions.UPDATE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successMessage: 'User has been edited successfully',
        errorMessage: null,
        resetMessage: !state.resetMessage,
      };
    case actions.SAVE_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        successMessage: 'User has been saved successfully',
        errorMessage: null,
        resetMessage: !state.resetMessage,
      };
    case actions.SAVE_USER_ERROR:
      return {
        ...state,
        isLoadingProperties: false,
        successMessage: null,
        errorMessage: 'There is a loading problem',
      };
    case actions.TOGGLE_HANDLE_MODAL_USER:
      return {
        ...state,
        modalActive: !state.modalActive,
        user: payload.data == null ? initState.user : payload.data,
      };
    case actions.DELETE_USER_ERROR:
      return {
        ...state,
        successMessage: null,
        resetMessage: !state.resetMessage,
        errorMessage: 'It is not possible to delete this client, it´s associated with some property',
      };
    case actions.REMOVE_NOTIFICATION_MESSAGE:
      return {
        ...state,
        successMessage: null,
        resetMessage: null,
        errorMessage: null
      };
    default:
      return state;
  }
}
