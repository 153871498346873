import actions from "./actions";

const initState = {
  isLoading: false,
  errorMessage: "",
  settings:[],
  setting: {
    fieldName: "",
    value: "",
    label:""
  },
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cities: payload.data,
        citiesSelector: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: "There is a loading problem",
      };
    case actions.SAVE_INTO_FIRESTORE:
        return {
        ...state,
        isLoading: true,
    };
    case actions.LOAD_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        settings: payload.data,
      };

    case actions.FIRESTORE_UPDATE:
      return {
        ...state,
        setting: payload.data,
      };
    default:
      return state;
  }
}
