import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./actions";
import {
    simpleQueryFirestoreWithFilterOrderBy
} from "../../components/utility/firestore.helper";
import { rsf } from "@iso/lib/firebase/firebase";
import omit from "lodash/omit";
import { getAccountIdPrefix } from '@iso/components/library/helpers/local_storage';

const COLLECTION_NAME = 'settings'; // change your collection


function* loadSettings(query) {
 try {
    const filtersSettings = null;
    const orderBy = { type: "asc", field: "fieldName" }
    const data = yield simpleQueryFirestoreWithFilterOrderBy(
        getAccountIdPrefix() + COLLECTION_NAME,
        filtersSettings,
        orderBy
    );
    let dataSource=[];
    Object.keys(data).map((item, index) => {
      return dataSource.push({
        ...data[item],
        key: item,
      });
    });

    yield put(actions.loadSettingsSuccess(dataSource));
  } catch (error) {
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* saveIntoFireStore(data) {
 const { payload } = data;
 try {
    switch (payload.actionName) {
      case "delete":
        yield call(rsf.firestore.deleteDocument, getAccountIdPrefix() + COLLECTION_NAME + "/" + payload.data.key);
        break;
      case "update":
        for (const item of payload.data) {
          yield call(
            rsf.firestore.setDocument,
            `${getAccountIdPrefix() + COLLECTION_NAME}/${item.key}`,
            {
              ...omit(item, ["key"]),
            }
          );
        }
        break;
      default:
        yield call(rsf.firestore.addDocument, getAccountIdPrefix() + COLLECTION_NAME, payload.data);
        break;
    }
    yield put({ type: actions.LOAD_SETTINGS });
  } catch (error) {
    yield put(actions.saveIntoFireStoreError(error));
  }

  return yield false;

}







export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_SETTINGS, loadSettings),
    takeEvery(actions.SAVE_INTO_FIRESTORE, saveIntoFireStore),
  ]);
}
