import { all } from "redux-saga/effects";
import authSaga from "@iso/redux/auth/saga";
import bookingSaga from "@iso/redux/booking/saga";
import invoiceSaga from "@iso/redux/invoice/saga";
import albaranSaga from "@iso/redux/albaran/saga";
import paymentSaga from "@iso/redux/payment/saga";
import serieSaga from "@iso/redux/serie/saga";
import propertySaga from "@iso/redux/property/saga";
import userSaga from "@iso/redux/user/saga";
import itemSaga from "@iso/redux/item/saga";
import featureSaga from "@iso/redux/feature/saga";
import citySaga from "@iso/redux/city/saga";
import logSaga from "@iso/redux/log/saga";
import settingsSaga from "@iso/redux/settings/saga";

export default function* rootSaga(getState) {
  yield all([
    authSaga(),
    bookingSaga(),
    itemSaga(),
    featureSaga(),
    citySaga(),
    logSaga(),
    albaranSaga(),
    paymentSaga(),
    serieSaga(),
    invoiceSaga(),
    propertySaga(),
    userSaga(),
    settingsSaga(),
  ]);
}
