const actions = {
  GET_INVOICES: 'GET_INVOICES',
  GET_INVOICES_SUCCESS: 'GET_INVOICES_SUCCESS',
  UPDATE_LIST_VIEW: 'UPDATE_LIST_VIEW',
  UPDATE_INVOICE: 'UPDATE_INVOICE',
  UPDATE_INVOICE_SUCCESS: 'UPDATE_INVOICE_SUCCESS',
  UPDATE_INVOICES_BULK: 'UPDATE_INVOICES_BULK',
  UPDATE_INVOICES_BULK_SUCCESS: 'UPDATE_INVOICES_BULK_SUCCESS',
  UPDATE_DATA_USER_INVOICE:'UPDATE_DATAUSER_INVOICE',
  SELECT_CURRENT_INVOICE: 'SELECT_CURRENT_INVOICE',
  EDIT_INVOICE: 'INVOICE_EDIT_INVOICE',
  UPDATE_INVOICE_ITEM_BOOKING: 'UPDATE_INVOICE_ITEM_BOOKING',
  GET_INVOICE: 'GET_INVOICE',
  GET_INVOICE_SUCCESS:'GET_INVOICE_SUCCESS',
  GET_INVOICE_ERROR: 'GET_INVOICE_ERROR',
  FIX_PAYMENTS_INVOICE: 'FIX_PAYMENTS_INVOICE',
  UPDATE_INVOICE_ITEM_BOOKING_SUCCESS: 'UPDATE_INVOICE_ITEM_BOOKING_SUCCESS',
  FIX_PAYMENTS_INVOICES_BULK: 'FIX_PAYMENTS_INVOICES_BULK',

  initData: filter => ({ type: actions.GET_INVOICES, payload:{
    filter
  } }),
  initDataItems: () => ({  type: 'ITEM_LOAD_FROM_FIRESTORE' }),
  fixPaymentsInvoice: () => ({ type: 'FIX_PAYMENTS_INVOICE'}),
  //action delete from invoices list
  deleteInvoice: (selected, filter) => {
    return (dispatch, getState) => {
      const invoices = getState().Invoice.invoices;
      const newInvoices = [];
      invoices.forEach(invoice => {
        const selectedIndex = selected.indexOf(invoice.key);
        if (selectedIndex === -1) {
          newInvoices.push(invoice);
        }else{
          dispatch({
            type: actions.UPDATE_INVOICE,
            payload: {
              invoices: newInvoices,
              invoice: invoice,
              filter,
              actionName: 'delete'
            }
          });
        }
      });

    };
  },
  //update invoice in store
  editInvoice: invoice => ({ type: actions.EDIT_INVOICE, invoice }),
  //save in database
  updateInvoice: (invoice, filter, actionName = 'insert') => {
    return (dispatch, getState) => {
      dispatch({
        type: actions.UPDATE_INVOICE,
        payload:{
          invoice,
          filter,
          actionName: actionName
        }
      });
    };
  },
  updateListView: (listView) => ({ type: actions.UPDATE_LIST_VIEW, payload: {listView: listView } }),
  updateInvoicesBulk: (invoices, series, filter) => {
    return {
      type: actions.UPDATE_INVOICES_BULK,
      payload: {
        invoices,
        series,
        filter
      }
    }
  },
  fixPaymentsInvoicesBulk: (invoices, series, filter) => {
    return {
      type: actions.FIX_PAYMENTS_INVOICES_BULK,
      payload: {
        invoices
      }
    }
  },
  invoiceUpdateItems: (dataBooking, previousBooking) =>
  {
    return (dispatch, getState) => {
      let itemList = [...dataBooking.cleaning_services_item_list, ...dataBooking.arrival_item_list, ...dataBooking.departure_item_list];
      let itemListPrevious = [...previousBooking.cleaning_services_item_list, ...previousBooking.arrival_item_list, ...previousBooking.departure_item_list];
      itemListPrevious.forEach(item => {
        let found = false;
        itemList.forEach(i => {
          if (i.itemId === item.itemId && i.invoice === item.invoice) {
            found = true;
          }
        });
        if (!found){
          itemList.push({ itemId: item.itemId, createdAt: item.createdAt, invoice: item.invoice, deleted: true });
        }
      });
      dispatch({
        type: actions.UPDATE_INVOICE_ITEM_BOOKING,
        payload:{
          items: itemList,
          bookingId: dataBooking.id,
          propertyId: dataBooking.property_id,
        }
      });
    }
  },
  getInvoice: (id) => {
    return {
      type: actions.GET_INVOICE,
      payload: { id }
    };
  },
  getInvoiceSuccess: data => ({
    type: actions.GET_INVOICE_SUCCESS,
    payload: { data },
  }),
  getInvoiceError: error => ({
    type: actions.GET_INVOICE_ERROR,
    payload: { error },
  }),

  updateDataUserInvoice:(dataUser)=>{ 
    return {
      type: actions.UPDATE_DATA_USER_INVOICE,
      payload: { dataUser }
    };
  }
};
export default actions;
