export default {
  siteName: "MEHOCA",
  siteIcon: "ion-flash",
  footerText: `Mehoca @ ${new Date().getFullYear()} Created by A-digital`,
  enableAnimatedRoute: false,
  apiUrl: "http://yoursite.com/api/",
  google: {
    analyticsKey: "UA-xxxxxxxxx-1",
  },
  dashboard: "/dashboard",
};

const siteConfig = {
  siteName: "Mehoca",
  siteIcon: "ion-flash",
  url: "https://menorcahomecare.com",
  footerText: "Mehoca ©2019 Created by A-Digital, Inc",
};

const roles = {
  superadmin: {
    type: "superadmin",
    label: "Super Admin",
    permissions: ["all"],
    defaultScreen: "dashboard"
  },
  admin: {
    type: "admin",
    label: "Admin",
    permissions: ["all"],
    defaultScreen: "dashboard"
  },
  bookingmanager: {
    type: "bookingmanager",
    label: "Booking Manager",
    permissions: [
      "dashboard",
      "users",
      "clients",
      "client-profile",
      "items",
      "invoice",
      "note",
      "list-properties",
      "list-bookings",
      "add-booking",
      "planning",
      "list-arrivals"
    ],
    defaultScreen: "dashboard"
  },
  client: {
    type: "client",
    label: "Client",
    permissions: [
        "dashboard",
        "clients",
        "client-profile",
        "only-read",
        "list-bookings",
        "invoices",
        "invoice",
        "add-booking",
        "view-invoice",
        "edit-booking",
        "view-booking",
        "planning"

    ],
    defaultScreen: "dashboard"
  },
  accountant: {
    type: "accountant",
    label: "Accountant",
    permissions: [
      "only-read",
      "invoice",
      "download-excel-invoice",
      "download-invoice",
      "invoices"
    ],
    defaultScreen: "invoices/invoice"
  },
  provider: {
    type: "provider",
    label: "Provider",
    permissions: ["all"],
    defaultScreen: "dashboard"
  },
  agency: {
    type: "agency",
    label: "Agency",
    permissions: [
            "dashboard",
			"planning",
		],
    defaultScreen: "dashboard"
  },
};

const invoiceSeries = {
  normal: {
    type: "normal",
    label: "Normal",
    enabled: true,
    id: 1,
  },
  lavanderia: {
    type: "lavanderia",
    label: "Lavandería",
    enabled: true,
    id: 2,
  },
  lavanderia_abonos: {
    type: "lavanderia_abonos",
    label: "Lavandería (Abonos)",
    enabled: true,
    id: 3,
  },
  normal_abonos: {
    type: "normal_abonos",
    label: "Normal (Abonos)",
    enabled: true,
    id: 4,
  },
  rapida: {
    type: "rapida",
    label: "Rápida",
    enabled: true,
    id: 5,
  },
  rapida_abonos: {
    type: "rapida_abonos",
    label: "Rápida (Abonos)",
    enabled: true,
    id: 6,
  },
  rental: {
    type: "rental",
    label: "Rental",
    enabled: false,
    id: 7,
  },
  rental_abonos: {
    type: "rental_abonos",
    label: "Rental (Abonos)",
    enabled: false,
    id: 8,
  },
  cool_oasis: {
    type: "cool_oasis",
    label: "Cool Oasis",
    enabled: true,
    id: 9,
  },
  cool_oasis_abonos: {
    type: "cool_oasis_abonos",
    label: "Cool Oasis (Abonos)",
    enabled: true,
    id: 10,
  },
  suplidos: {
    type: "suplidos",
    label: "Suplidos",
    enabled: true,
    id: 11,
  },
  suplidos_abonos: {
    type: "suplidos_abonos",
    label: "Suplidos (Abonos)",
    enabled: true,
    id: 12,
  },
};

const noteSeries = {
  normal: {
    type: "normal",
    label: "Note",
    enabled: true,
    id: 101,
  }
};

const cityStatus = {
  Enabled: {
    id: 1,
    name:"Enabled"
  },
  Disabled: {
    id: 2,
    name:"Disabled"
  },

};


const ccEmailBusiness = '';
export { siteConfig, roles, invoiceSeries, noteSeries, ccEmailBusiness, cityStatus };
