const actions = {
  GET_ALBARAN: 'GET_ALBARAN',
  UPDATE_ALBARAN: 'UPDATE_ALBARAN',
  FIND_ALBARAN_SAGA: 'FIND_ALBARAN_SAGA',
  UPDATE_ALBARAN_SAGA: 'UPDATE_ALBARAN_SAGA',
  SELECT_CURRENT_ALBARAN: 'SELECT_CURRENT_ALBARAN',
  UPDATE_EDIT_ALBARAN: 'ALBARAN_UPDATE_EDIT_ALBARAN',
  initDataAlbaran: filter => ({ type: actions.GET_ALBARAN, payload:{
    filter
  } }),
  deleteAlbaran: selected => {
    return (dispatch, getState) => {
      const albaranes = getState().Albaran.albaranes;
      const newAlbaranes = [];
      albaranes.forEach(albaran => {
        const selectedIndex = selected.indexOf(albaran.key);
        if (selectedIndex === -1) {
          newAlbaranes.push(albaran);
        }else{
          dispatch({
            type: actions.UPDATE_ALBARAN_SAGA,
            payload: {
              albaranes: newAlbaranes,
              albaran: albaran,
              actionName: 'delete'
            }
          });
        }
      });

    };
  },
  updateAlbaran: albaran => {
    return (dispatch, getState) => {
      let actionName = 'insert';
      const albaranes = getState().Albaran.albaranes;
      const index = albaranes.map(inv => inv.id).indexOf(albaran.id);
      if (index === -1) {
        albaranes.push(albaran);
      } else {
        actionName = 'update';
        albaranes[index] = albaran;
      }
      dispatch({
        type: actions.UPDATE_ALBARAN_SAGA,
        payload:{
          albaranes,
          albaran,
          actionName: actionName
        }
      });
      
    };
  },
  findAlbaran: filter => ({
    type: actions.FIND_ALBARAN_SAGA,
    payload:{
      filter
    }
  }),
  selectCurrentAlbaran: id => ({ type: actions.SELECT_CURRENT_ALBARAN, id }),
};
export default actions;
