import moment from 'moment';

export default class DateFormatter {

  static getCurrentYear = () => {
    return new Date().getFullYear();
  }

  static getCurrentDate = () => {
    return moment();
  }

  static getDate = (date) => {
    return moment(date);
  }

  static getValueOfDate = (date) => {
    return date ? moment(date).valueOf() : moment().valueOf();
  }

  static getValueOfStartedDate = (date) => {
    return date ? moment(date).startOf("day").valueOf() : moment().startOf("day").valueOf();
  }

  static getValueOfEndedDate = (date) => {
    return date ? moment(date).endOf("day").valueOf() : moment().endOf("day").valueOf();
  }

  static getFormatString = (date, format) => {
    return date ? moment(date).format(format) : moment().format(format);
  }

  static addDaysToCurrentDate = (date, days, key) => {
    return date ? moment(date).add(days, key) : moment().add(days, key);
  }

  static subtractDaysToCurrentDate = (date, days, key) => {
    return moment(date).subtract(days, key);
  }

  static diffDays = (startDate, endDate) => {
    return moment(endDate).diff(startDate, 'days')
  }

  static getCleanUTCValue = (date) => {
    return date ? date.utc().set({ hour: 0, minute: 0, second: 0, millisecond: 0, }).valueOf() : date;
  }

  static getLastDayMonthValue = (date) => {
    return moment(new Date(date.getFullYear(), date.getMonth()+ 1, 0, 12, 0)).valueOf();
  }

  static getLastDayYearValue = (date) => {
    return moment(new Date(date.getFullYear(), 11, 31, 23, 59)).valueOf();
  }

  static getFirstDayYearValue = (date) => {
    return moment(new Date(date.getFullYear(), 0, 1, 0, 0)).valueOf();
  }

  static formatDateToValue = (date) => {
    return date ? this.getValueOfStartedDate(date) : this.getValueOfStartedDate();
  }

  static formatNextDateToValue = (date) => {
    return date ? this.getValueOfEndedDate(this.addDaysToCurrentDate(this.getValueOfStartedDate(date), 1, 'd')) :
        this.getValueOfEndedDate(this.addDaysToCurrentDate(this.getValueOfStartedDate(), 1, 'd'));
  }

  static getPredefinedRanges = () => {
    return [
      {
        label: new Date().getFullYear() - 3,
        value: [new Date(new Date().getFullYear() - 3, 0, 1), new Date(new Date().getFullYear()-2, 0, 0)],
        placement: 'left'
      },
      {
        label: new Date().getFullYear() - 2,
        value: [new Date(new Date().getFullYear() - 2, 0, 1), new Date(new Date().getFullYear()-1, 0, 0)],
        placement: 'left'
      },
      {
        label: new Date().getFullYear() - 1,
        value: [new Date(new Date().getFullYear() - 1, 0, 1), new Date(new Date().getFullYear(), 0, 0)],
        placement: 'left'
      },
      {
        label: new Date().getFullYear(),
        value: [new Date(new Date().getFullYear(), 0, 1), new Date(new Date().getFullYear()+1, 0, 0)],
        placement: 'left'
      },
      {
        label: new Date().getFullYear() + 1,
        value: [new Date(new Date().getFullYear()+1, 0, 1), new Date(new Date().getFullYear()+1, 11, 31)],
        placement: 'left'
      },
      {
        label: new Date().getFullYear() + 2,
        value: [new Date(new Date().getFullYear()+2, 0, 1), new Date(new Date().getFullYear()+2, 11, 31)],
        placement: 'left'
      }
    ]
  }

}
