const localDataName = 'mateAlbaran';
const newAlbaran = {
  orderStatus: 'draft',
  orderDate: new Date().getTime(),
  currency: '$',
  billTo: '',
  billToAddress: '',
  billFrom: '',
  billFromAddress: '',
  albaranList: [
    {
      key: 1,
      itemName: '',
      itemType: 'A',
      noTaxable: false,
      costs: 0,
      qty: 0,
      price: 0,
      createdAt: Date.now()
    }
  ],
  taxable: 0,
  subTotal: 0,
  vatRate: 21,
  vatPrice: 0,
  totalCost: 0,
  deleted_at: null
};

export {
  localDataName,
  newAlbaran
};
