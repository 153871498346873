import moment from "moment";

export const getValidNextReservationNumber = data => {
    return data.length ? (parseInt(data[0].reservation_number) + 1).toString().padStart(6,'0') : '000001'
}

export const getTimezonesArray = dateFilter => {
    let dates = [];
    for (let i = 0; i < dateFilter.length; i++){
        let dateMoment = moment(dateFilter[i]).startOf("day").valueOf();
        for(let j = 0; j < 24; j++){
            dates.push(dateMoment + ((j * 3600 * 1000)));
        }
    }
    return dates;
}
