const DOCUMENT = 'BOOKING';

const actions = {
  GET_NEXT_RESERVATION_NUMBER: DOCUMENT + '_GET_NEXT_RESERVATION_NUMBER',
  GET_NEXT_RESERVATION_NUMBER_SUCCESS: DOCUMENT + '_GET_NEXT_RESERVATION_NUMBER_SUCCESS',
  GET_NEXT_RESERVATION_NUMBER_ERROR: DOCUMENT + '_GET_NEXT_RESERVATION_NUMBER_ERROR',

  LOAD_BOOKING: DOCUMENT + '_LOAD',
  LOAD_BOOKING_SUCCESS: DOCUMENT + '_LOAD_SUCCESS',
  LOAD_BOOKING_ERROR: DOCUMENT + '_LOAD_ERROR',

  LOAD_BOOKINGS: DOCUMENT + '_LOAD_BOOKINGS',
  LOAD_BOOKINGS_SUCCESS: DOCUMENT + '_LOAD_BOOKINGS_SUCCESS',
  LOAD_BOOKINGS_ERROR: DOCUMENT + '_LOAD_BOOKINGS_ERROR',

  LOAD_BOOKINGS_WITH_PROPERTIES: DOCUMENT + '_LOAD_WITH_PROPERTIES',
  LOAD_BOOKINGS_WITH_PROPERTIES_SUCCESS: DOCUMENT + '_LOAD_WITH_PROPERTIES_SUCCESS',
  LOAD_BOOKINGS_WITH_PROPERTIES_ERROR: DOCUMENT + '_LOAD_WITH_PROPERTIES_ERROR',

  LOAD_ARRIVAL_DEPARTURE_WITH_PROPERTIES: DOCUMENT + '_ARRIVAL_DEPARTURE_LOAD_WITH_PROPERTIES',
  LOAD_ARRIVAL_DEPARTURE_WITH_PROPERTIES_SUCCESS: DOCUMENT + '_ARRIVAL_DEPARTURE_LOAD_WITH_PROPERTIES_SUCCESS',
  LOAD_ARRIVAL_DEPARTURE_WITH_PROPERTIES_ERROR: DOCUMENT + '_ARRIVAL_DEPARTURE_LOAD_WITH_PROPERTIES_ERROR',

  SAVE_BOOKING: DOCUMENT + '_SAVE',
  SAVE_BOOKING_SUCCESS: DOCUMENT + '_SAVE_SUCCESS',

  UPDATE_BOOKING: DOCUMENT + '_UPDATE',
  UPDATE_BOOKING_SUCCESS: DOCUMENT + '_UPDATE_SUCCESS',

  STORE_BOOKING_ERROR: DOCUMENT + '_SAVE_ERROR',

  UPDATE_STATUS_BOOKING: DOCUMENT + '_UPDATE_STATUS',
  UPDATE_STATUS_BOOKING_SUCCESS: DOCUMENT + 'U_PDATE_STATUS_SUCCESS',
  UPDATE_STATUS_BOOKING_ERROR: DOCUMENT + '_UPDATE_STATUS_ERROR',

  DELETE_BOOKING: DOCUMENT + '_DELETE_BOOKING',
  DELETE_BOOKING_SUCCESS: DOCUMENT + '_DELETE_BOOKING_SUCCESS',
  DELETE_BOOKING_ERROR: DOCUMENT + '_DELETE_BOOKING_ERROR',

  REMOVE_NOTIFICATION_MESSAGE: DOCUMENT + '_REMOVE_NOTIFICATION_MESSAGE',

  loadBookings: (filterBooking) => ({
    type: actions.LOAD_BOOKINGS,
    payload: { filterBooking },
  }),

  loadBookingsSuccess: data => ({
    type: actions.LOAD_BOOKINGS_SUCCESS,
    payload: { data },
  }),

  loadBookingsError: error => ({
    type: actions.LOAD_BOOKINGS_ERROR,
    payload: { error },
  }),

  getNextReservationNumber: () => ({
    type: actions.GET_NEXT_RESERVATION_NUMBER,
    payload: { },
  }),

  getNextReservationNumberError: error => ({
    type: actions.GET_NEXT_RESERVATION_NUMBER_ERROR,
    payload: { error },
  }),

  loadBooking: id => ({
    type: actions.LOAD_BOOKING,
    payload: { id },
  }),

  loadBookingSuccess: data => ({
    type: actions.LOAD_BOOKING_SUCCESS,
    payload: { data },
  }),

  loadBookingError: error => ({
    type: actions.LOAD_BOOKING_ERROR,
    payload: { error },
  }),

  loadBookingsWithProperties: (filterBooking, filterProperty) => {
    return { type: actions.LOAD_BOOKINGS_WITH_PROPERTIES,
      payload: { filterBooking, filterProperty } };
  },

  loadBookingsWithPropertiesSuccess: (data) => ({
    type: actions.LOAD_BOOKINGS_WITH_PROPERTIES_SUCCESS,
    payload: { data },
  }),

  loadBookingsWithPropertiesError: error => ({
    type: actions.LOAD_BOOKINGS_WITH_PROPERTIES_ERROR,
    payload: { error },
  }),

  loadArrivalDepartureWithProperties: (filterBooking, filterProperty) => {
    return { type: actions.LOAD_ARRIVAL_DEPARTURE_WITH_PROPERTIES,
      payload: { filterBooking, filterProperty} };
  },

  loadArrivalDepartureWithPropertiesSuccess: (data) => ({
    type: actions.LOAD_ARRIVAL_DEPARTURE_WITH_PROPERTIES_SUCCESS,
    payload: { data },
  }),

  loadArrivalDepartureWithPropertiesError: error => ({
    type: actions.LOAD_ARRIVAL_DEPARTURE_WITH_PROPERTIES_ERROR,
    payload: { error },
  }),

  saveBooking: (data, actionName = 'insert') => ({
    type: actions.SAVE_BOOKING,
    payload: { data, actionName },
  }),

  saveBookingSuccess: () => ({
    type: actions.SAVE_BOOKING_SUCCESS,
  }),

  updateBooking: (data, actionName = 'update') => ({
    type: actions.UPDATE_BOOKING,
    payload: { data, actionName },
  }),

  updateBookingSuccess: () => ({
    type: actions.UPDATE_BOOKING_SUCCESS,
  }),

  storeBookingError: error => ({
    type: actions.STORE_BOOKING_ERROR,
    payload: { error },
  }),

  updateStatusBooking: data => ({
    type: actions.UPDATE_STATUS_BOOKING,
    payload: { data },
  }),

  updateStatusBookingSuccess: data => ({
    type: actions.UPDATE_STATUS_BOOKING_SUCCESS,
    payload: { data },
  }),

  updateStatusBookingError: error => ({
    type: actions.UPDATE_STATUS_BOOKING_ERROR,
    payload: { error },
  }),

  deleteBooking: (filterBooking, data) => ({
    type: actions.DELETE_BOOKING,
    payload: { filterBooking, data },
  }),

  deleteBookingSuccess: () => ({
    type: actions.DELETE_BOOKING_SUCCESS,
  }),

  deleteBookingError: error => ({
    type: actions.DELETE_BOOKING_ERROR,
    payload: { error },
  }),

  cleanNotificationMessage: () => ({
    type: actions.REMOVE_NOTIFICATION_MESSAGE
  }),
};


export default actions;
