export function getAccountIdPrefix(){
    let accountId = localStorage.getItem('account_id');
    return accountId ? accountId +'_' : '';
}

export function getAccountData(){
    let accountData = JSON.parse(localStorage.getItem('data_account'));
    return accountData ? accountData : null;
}

export function getLoggedUserId(){
    let userId = localStorage.getItem('id_user');
    return userId ? userId : null;
}
