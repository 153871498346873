import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./actions";
import { simpleQueryFirestoreWithFilterOrderBy } from "../../components/utility/firestore.helper";
import { rsf } from "@iso/lib/firebase/firebase";
import omit from "lodash/omit";
import { getAccountIdPrefix } from '@iso/components/library/helpers/local_storage';

const COLLECTION_NAME = 'payments'; // change your collection

function* loadPayments({ payload }) {
  const {
    meta: { resolve, reject } = {}
    } = payload;
  try {
    const { filter, singlePayment } = payload;
    let filters = [];
    let orderBy = { field: "date", type: "asc" };
    if (filter !== undefined) {
      if (filter.invoice) {
        filters.push({
          field: "invoice",
          operator: "==",
          value: filter.invoice,
        });
      }
      if (filter.type) {
        filters.push({
          field: "type",
          operator: "==",
          value: filter.type,
        });
      }
      if (filter.billToId) {
        filters.push({
          field: "billToId",
          operator: "==",
          value: filter.billToId,
        });
      }
      if (filter.idOwner) {
        filters.push({
          field: "idOwner",
          operator: "==",
          value: filter.idOwner,
        });
      }
      if (filter.filterYearBegin && filter.filterYearEnd) {
        filters.push({
          field: "date",
          operator: ">",
          value: parseInt(filter.filterYearBegin),
        });
        filters.push({
          field: "date",
          operator: "<=",
          value: parseInt(filter.filterYearEnd),
        });
      }
    }
    filters.push({ field: "deleted_at", operator: "==", value: null });
    let data = yield simpleQueryFirestoreWithFilterOrderBy(
        getAccountIdPrefix() + COLLECTION_NAME,
      filters,
      orderBy
    );

    if (filter !== undefined && singlePayment) {
      let object = Object.keys(data);
      yield put(
        actions.loadPaymentsSuccessDocument({
          key: object[0],
          ...data[object[0]],
        })
      );
    }
    yield put(actions.loadPaymentsSuccess(data));
    if(resolve)
      yield call(resolve, data);
  } catch (error) {
    console.log(error);
    yield put(actions.loadPaymentsError(error));
    if(reject)
    yield call(reject, error);
  }
}

function* storePayment({ payload }) {
  const { data, actionName } = payload;
  try {
    switch (actionName) {
      case "delete":
        yield call(
          rsf.firestore.setDocument,
          `${getAccountIdPrefix() + COLLECTION_NAME}/${data.key}`,
          {
            ...omit(data, ["key"]),
            deleted_at: new Date().getTime(),
          }
        );
        break;
      case "update":
        yield call(
          rsf.firestore.setDocument,
          `${getAccountIdPrefix() + COLLECTION_NAME}/${data.key}`,
          {
            ...omit(data, ["key"]),
          }
        );
        break;
      default:
        yield call(rsf.firestore.addDocument, getAccountIdPrefix() + COLLECTION_NAME, data);
        break;
    }
    yield put({
      type: actions.LOAD_PAYMENTS,
      payload: { filter: { billToId: data.billToId, type: data.type } },
    });
  } catch (error) {
    console.log(error);
    yield put(actions.savePaymentError(error));
  }
}

function* loadPaymentsDocument({ payload }) {
  try {
    const { key } = payload;
    const snapshot = yield call(
      rsf.firestore.getDocument,
        getAccountIdPrefix() + COLLECTION_NAME + "/" + key
    );
    let data = snapshot.data();
    yield put(actions.loadPaymentsSuccessDocument({ key: key, ...data }));
  } catch (error) {
    console.log(error);
    yield put(actions.loadPaymentsErrorDocument(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_PAYMENTS, loadPayments),
    takeEvery(actions.SAVE_PAYMENT, storePayment),
    takeEvery(actions.LOAD_PAYMENTS_DOCUMENT, loadPaymentsDocument),
  ]);
}
