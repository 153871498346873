import clone from 'clone';
import { newAlbaran } from '@iso/pages/Albaran/config';
import actions from './actions';

const initState = {
  albaranes: [],
  initialAlbaranes: false,
  currentAlbaran: {},
  editableAlbaran: {},
  isNewAlbaran: false,
};

export default function cardReducer(state = initState, { type, payload, ...action }) {
  switch (type) {
    case actions.UPDATE_ALBARAN: {
      const currentAlbaran = action.albaran
        ? action.albaran
        : state.currentAlbaran;
      return {
        ...state,
        albaranes: action.albaranes,
        currentAlbaran: clone(currentAlbaran),
        initialAlbaranes: true,
        isNewAlbaran: false
      };
    }
    case actions.UPDATE_ALBARAN_SAGA: {
      const currentAlbaran = payload.albaran
        ? payload.albaran
        : state.currentAlbaran;
      return {
        ...state,
        albaranes: payload.albaranes,
        currentAlbaran: clone(currentAlbaran),
        initialAlbaranes: true,
        isNewAlbaran: false
      };
    }
    case actions.FIND_ALBARAN_SAGA: {
      return{
          ...state,
          albaranes: payload.albaranes
      }
    }
    case actions.SELECT_CURRENT_ALBARAN: {
      const albaranes = state.albaranes;
      const index = albaranes.map(albaran => albaran.id).indexOf(parseInt(action.id));
      const isNewAlbaran = index === -1;
      const currentAlbaran = isNewAlbaran
        ? {
            id: action.id,
            number: `#${action.id}`,
            key: action.id,
            ...newAlbaran
          }
        : albaranes[index];
      return {
        ...state,
        currentAlbaran,
        isNewAlbaran,
        editableAlbaran: clone(currentAlbaran)
      };
    }
    case actions.UPDATE_EDIT_ALBARAN:
      return {
        ...state,
        editableAlbaran: clone(action.albaran)
      };
    //new code
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        albaranes: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    default:
      return state;
  }
}
