import actions from "./actions";

const initState = {
  isLoading: false,
  errorMessage: false,
  itemsSelector: {},
  items: [],
  itemsByProperties: [],
  modalActive: false,
  item: {
    id: null,
    name: "",
    price: 0,
    enabled: "true", // publish
    description: "",
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
    prices: [],
    propertiesIds: [],
  },
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        items: payload.data,
        itemsSelector: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: "There is a loading problem",
      };
    case actions.LOAD_ITEMS_SELECTOR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        itemsSelector: payload.data,
      };
    case actions.LOAD_ITEMS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        itemsSelector: payload.data,
        items: payload.data,
      };
    case actions.LOAD_BY_PROPERTY_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
      };
    case actions.LOAD_BY_PROPERTY_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        itemsByProperties: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_BY_PROPERTY_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: "There is a loading problem",
      };
    case actions.TOGGLE_FIRESTORE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        item:
          payload.data == null
            ? {
                id: null,
                name: "",
                price: 0,
                enabled: "true", // publish
                description: "",
                created_at: new Date().getTime(),
                deleted_at: null, // soft delete
                prices: [],
                propertiesIds: [],
              }
            : payload.data,
      };
    case actions.FIRESTORE_UPDATE:
      return {
        ...state,
        item: payload.data,
      };
    default:
      return state;
  }
}
