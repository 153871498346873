import actions from './actions';
import {generateSystemId} from "../../components/library/helpers/idGenerator";

const initState = {
  isLoading: false,
  errorMessage: false,
  logs: {},
  log: {
    id: generateSystemId(),
    user_id: null,
    text: '', // publish
    module: '',
    type: '',
    checked: false,
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function reducer(
  state = initState,
  { type, payload, newRecord }
) {
  switch (type) {
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        logs: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: 'There is a loading problem',
      };
    case actions.FIRESTORE_UPDATE:
      return {
        ...state,
        log: payload.data,
      };
    default:
      return state;
  }
}
