import actions from "./actions";

const initState = {
  isLoading: false,
  errorMessage: false,
  citiesSelector: {},
  cities: [],
  citiesByProperties: [],
  modalActive: false,
  city: {
    id: null,
    name: "",
    enabled: "true", // publish
    created_at: new Date().getTime(),
    deleted_at: null, // soft delete
  },
};

export default function reducer(state = initState, { type, payload }) {
  switch (type) {
    case actions.LOAD_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
        modalActive: false,
      };
    case actions.LOAD_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cities: payload.data,
        citiesSelector: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: "There is a loading problem",
      };
    case actions.LOAD_CITIES_SELECTOR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        citiesSelector: payload.data,
      };
    case actions.LOAD_CITIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        citiesSelector: payload.data,
        cities: payload.data,
      };
    case actions.LOAD_BY_PROPERTY_FROM_FIRESTORE:
      return {
        ...state,
        isLoading: true,
        errorMessage: false,
      };
    case actions.LOAD_BY_PROPERTY_FROM_FIRESTORE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        citiesByProperties: payload.data,
        errorMessage: false,
      };
    case actions.LOAD_BY_PROPERTY_FROM_FIRESTORE_ERROR:
      return {
        ...state,
        isLoading: false,
        errorMessage: "There is a loading problem",
      };
    case actions.TOGGLE_FIRESTORE_HANDLE_MODAL:
      return {
        ...state,
        modalActive: !state.modalActive,
        city:
          payload.data == null
            ? {
                id: null,
                name: "",
                enabled: "true", // publish
                created_at: new Date().getTime(),
                deleted_at: null, // soft delete
              }
            : payload.data,
      };
    case actions.FIRESTORE_UPDATE:
      return {
        ...state,
        city: payload.data,
      };
    default:
      return state;
  }
}
