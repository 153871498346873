import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./actions";
import { simpleQueryFirestoreWithFilter } from "../../components/utility/firestore.helper";
import { rsf } from "@iso/lib/firebase/firebase";
import omit from "lodash/omit";
import { getAccountIdPrefix } from '@iso/components/library/helpers/local_storage';
import {generateSystemId} from "../../components/library/helpers/idGenerator";

const COLLECTION_NAME = 'features'; // change your collection

function* loadFromFirestore() {
  try {
    const filter = {
      field: "deleted_at",
      operator: "==",
      value: null,
    };
    let data = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + COLLECTION_NAME, [filter]);
    yield put(actions.loadFromFireStoreSuccess(data));
  } catch (error) {
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* loadFeaturesSelector(query) {
  try {
    const filtersFeature = query && query.payload.filterFeature;
    const data = yield simpleQueryFirestoreWithFilter(
        getAccountIdPrefix() + COLLECTION_NAME,
      filtersFeature
    );
    yield put(actions.loadFeaturesSelectorSuccess(data));
  } catch (error) {
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* loadFeatures(query) {
  try {
    const filtersFeature = query && query.payload.filterFeature;
    const data = yield simpleQueryFirestoreWithFilter(
        getAccountIdPrefix() + COLLECTION_NAME,
      filtersFeature
    );

    yield put(actions.loadFeaturesSuccess(data));
  } catch (error) {
    yield put(actions.loadFromFireStoreError(error));
  }
}

function* loadByPropertyFromFireStore({ payload }) {
  try {
    const filter = [
      {
        field: "deleted_at",
        operator: "==",
        value: null,
      },
      {
        field: "propertiesIds",
        operator: "array-contains",
        value: payload.data,
      },
    ];
    let data = yield simpleQueryFirestoreWithFilter(getAccountIdPrefix() + COLLECTION_NAME, filter);
    yield put(actions.loadByPropertyFromFireStoreSuccess(data));
  } catch (error) {
    yield put(actions.loadByPropertyFromFireStoreError(error));
  }
}

function* storeIntoFirestore({ payload }) {
  const { data, actionName } = payload;
  try {
    switch (actionName) {
      case "delete":
        yield call(rsf.firestore.deleteDocument, getAccountIdPrefix() + COLLECTION_NAME + "/" + data.key);
        break;
      case "update":
        yield call(
          rsf.firestore.setDocument,
          `${getAccountIdPrefix() + COLLECTION_NAME}/${data.key}`,
          {
            ...omit(data, ["key"]),
          }
        );
        break;
      default:
        data.id = generateSystemId();
        yield call(rsf.firestore.addDocument, getAccountIdPrefix() + COLLECTION_NAME, data);
        break;
    }
    yield put({ type: actions.LOAD_FROM_FIRESTORE });
  } catch (error) {
    yield put(actions.saveIntoFireStoreError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_FROM_FIRESTORE, loadFromFirestore),
    takeEvery(actions.LOAD_FEATURES_SELECTOR, loadFeaturesSelector),
    takeEvery(actions.LOAD_FEATURES, loadFeatures),
    takeEvery(
      actions.LOAD_BY_PROPERTY_FROM_FIRESTORE,
      loadByPropertyFromFireStore
    ),
    takeEvery(actions.SAVE_INTO_FIRESTORE, storeIntoFirestore),
  ]);
}
